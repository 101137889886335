@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

#agentChat {
  .agent-bubble {
    p {
      a {
        text-decoration: underline;
      }

      padding-bottom: 0.5em;
    }
  }
}
